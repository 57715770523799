/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

import config from "./config";
import shevy from "./typography";

const { content, baseSpacing, lineHeightSpacing } = shevy;

const _Button = styled.button`
    ${content}

    background: none;
    /*color: rgba(85, 111, 181, 0.95);*/
    color: rgba(246, 114, 128, 0.9);
    cursor: pointer;
    display: inline-block;
    font-family: ${config.baseSansSerifFontFamily};
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    font-size: 0.6rem;
    line-height: ${lineHeightSpacing(1)};

    /*outline: 1px solid rgba(85, 111, 181, 0.95);*/
    outline: 1px solid rgba(246, 114, 128, 0.95);

    margin: 0;
    padding: ${baseSpacing(0.5)} ${baseSpacing(1)};

    &:focus,
    &:hover {
        background: rgba(255, 255, 255, 0.5);
        color: rgba(246, 114, 128, 0.7);

        outline: 3px solid rgba(246, 114, 128, 0.8);
    }
`;

const Button = ({ href = "", children, ...otherProps }) => {
    const component = href ? "a" : "button";
    return (
        <_Button as={component} href={href} {...otherProps}>
            {children}
        </_Button>
    );
};

export default Button;
