/** @jsx jsx */
/* eslint-disable-next-line no-unused-vars */
import React from "react";
import { jsx, css } from "@emotion/core";

import shevy from "../styleguide/typography";
import { H2, H6 } from "../styleguide/heading";

import InternalLink from "./internal-link";

const linkStyle = css`
    color: inherit;
    text-decoration: none;
`;

const Article = ({
    title,
    children,
    href = "",
    rawDate = "",
    formattedDate = "",
    timeToRead = 0,
}) => {
    let metadata = null;
    if (rawDate && formattedDate) {
        metadata = (
            <>
                <time dateTime={rawDate}>{formattedDate}</time>
                {timeToRead && ` · ${timeToRead} min. read`}
            </>
        );
    }

    return (
        <article>
            <header
                css={css`
                    text-align: center;
                    margin-bottom: ${shevy.baseSpacing(2)};
                `}
            >
                <H2>
                    {href ? (
                        <InternalLink css={linkStyle} to={href}>
                            {title}
                        </InternalLink>
                    ) : (
                        title
                    )}
                </H2>

                {metadata && (
                    <H6>
                        {href ? (
                            <InternalLink css={linkStyle} to={href}>
                                {metadata}
                            </InternalLink>
                        ) : (
                            metadata
                        )}
                    </H6>
                )}
            </header>

            {children}
        </article>
    );
};

export default Article;
