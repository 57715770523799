/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { tint } from "polished";

import config from "../styleguide/config";
import shevy from "../styleguide/typography";

import InternalLink from "./internal-link";

export const Paginator = styled.div`
    align-content: stretch;
    align-items: stretch;
    background: rgba(255, 255, 255, 0.6);
    border-top: 1px solid rgba(112, 112, 112, 0.2);
    display: flex;
    flex-flow: row wrap;
    text-align: center;

    @media (max-width: 550px) {
        flex-flow: column wrap;
    }

    @media (min-width: 550px) {
        flex-flow: row wrap;
    }
`;

export const PaginatorItem = styled.div`
    display: flex;
    flex: 1;
`;

export const PaginatorItemDivider = styled.div`
    background: rgba(112, 112, 112, 0.2);
    width: 1px;

    @media (max-width: 550px) {
        display: none;
    }

    @media (min-width: 550px) {
        display: block;
    }
`;

const _PaginatorLink = styled(InternalLink)`
    display: block;
    flex: 1;
    padding: ${shevy.baseSpacing(1.5)};

    &:hover {
        /*background: rgba(255, 255, 255, 0.6);*/
        background: rgba(246, 114, 128, 0.9);
        color: rgba(255, 255, 255, 0.95);
    }

    &:hover div {
        color: rgba(255, 255, 255, 0.95);
    }
`;

export const PaginatorLink = ({ to, label = "", children, ...otherProps }) => (
    <_PaginatorLink to={to} {...otherProps}>
        {label ? (
            <div
                css={css`
                    color: ${tint(0.02, config.baseFontColour)};
                    display: block;
                    font-family: ${config.baseSansSerifFontFamily};
                    font-weight: 500;
                    font-size: 0.6rem;
                    line-height: ${shevy.lineHeightSpacing(1)};
                    text-transform: uppercase;
                `}
            >
                {label}
            </div>
        ) : null}
        {children}
    </_PaginatorLink>
);
